<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Contatos cadastrados</h4>
        <el-col :md="15">
          <el-row type="flex" justify="end">
            <el-button
              type="primary"
              icon="el-icon-plus"
              size="medium"
              @click="openContactModal(null)"
            ></el-button>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="contacts"
      style="width: 100%; z-index: 0"
    >
      <el-table-column prop="name" label="nome"> </el-table-column>
      <el-table-column prop="phone" label="telefone"> </el-table-column>
      <el-table-column prop="email" label="email"> </el-table-column>
      <el-table-column label="ações" :min-width="80">
        <template #default="cat">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openContactModal(cat.row)"
              >
                <i class="el-icon-edit"></i
              ></el-button>
              <el-popconfirm
                @confirm="deleteContact(cat.row)"
                title="
              Remover o contato?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <contact-modal
      :showModal="showContactModal"
      :contact="contact"
      @close-modal="showContactModal = false"
      @should-update="fetchContacts"
    ></contact-modal>
  </el-card>
</template>

<script>
import ContactModal from "./modals/ContactModal.vue";

export default {
  name: "ContactsPage",
  components: { ContactModal },
  data: () => ({
    hasError: false,
    isLoading: true,
    contacts: null,
    contact: null,
    showContactModal: false,
  }),
  mounted() {
    this.fetchContacts();
  },
  methods: {
    openContactModal(e) {
      (this.contact = e), (this.showContactModal = true);
    },
    deleteContact(c) {
      fetch(`${this.$store.state.apiUrl}contacts/${c.uid}`, {
        credentials: "include",
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.json();
        })
        .then(() => {
          this.fetchContacts();
          this.$notify({
            title: "Contato removido com sucesso.",
            type: "success",
            position: "bottom-right",
          });
        })
        .catch((e) => {
          this.$notify({
            title: "Não foi possível remover o contato",
            type: "error",
            message: e.message,
            position: "bottom-right",
          });
        });
    },
    fetchContacts() {
      const url = new URL(`${this.$store.state.apiUrl}contacts`);
      url.search = new URLSearchParams({ type: "Produtor" });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.contacts = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
tr.unavailable {
  background-color: #fffde7;
}
tr.problem {
  background-color: #fbe9e7;
}
</style>