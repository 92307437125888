<template>
  <el-dialog :title="ModalTitle" v-model="shouldShow">
    <form>
      <base-input label="Nome:" v-model="contact_.name"> </base-input>
      <base-input
        label="Telefone:"
        v-model="contact_.phone"
        mask="!(##!)# ####!-####"
      >
      </base-input>
      <base-input label="Email:" v-model="contact_.email"> </base-input>
      <el-select
        v-model="contact_.group"
        filterable
        size="medium"
        allow-create
        :loading="!contact_groups"
        @visible-change="fetchContactGroups"
        remote
        default-first-option
        placeholder="Selecione ou crie um grupo"
      >
        <el-option
          v-for="item in ContactGroups"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          class="button"
          @click="save"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
//import BaseInput from "../../components/BaseInput.vue";
import { ElNotification } from "element-plus";
import BaseInput from "../../components/BaseInput.vue";
export default {
  props: {
    contact: {
      type: Object,
    },
    showModal: {
      type: Boolean,
    },
    type: {
      type: String,
      default() {
        return "Produtor";
      },
    },
  },
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      contact_: this.contact || {},
      contact_groups: null,
      isLoadingSave: false,
      shortcuts: [
        {
          text: "Hoje",
          value: new Date(),
        },
        {
          text: "Ontem",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            return date;
          })(),
        },
        {
          text: "Segunda-feira",
          value: (() => {
            const date = new Date();
            date.setTime(
              date.getTime() - 3600 * 1000 * 24 * (date.getDay() - 1)
            );
            return date;
          })(),
        },
        {
          text: "Uma semana atrás",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            return date;
          })(),
        },
      ],
    };
  },
  watch: {
    contact(v) {
      this.contact_ = v || {};
    },
  },
  computed: {
    ModalTitle() {
      return this.contact ? "Editar contato" : "Cadastrar contato";
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
    ContactGroups() {
      return this.contact_groups || [];
    },
  },
  methods: {
    save() {
      this.isLoadingSave = true;
      return this.contact ? this.updateContact() : this.createContact();
    },
    createContact() {
      fetch(`${this.$store.state.apiUrl}contacts`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify({ ...this.contact_, type: this.type }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            this.shouldShow = false;
            this.contact_ = {};
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Não foi possível cadastrar o produto",
              message: e[0].message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
        });
    },
    updateContact() {
      fetch(`${this.$store.state.apiUrl}contacts/${this.contact_.uid}`, {
        credentials: "include",
        method: "PUT",
        body: JSON.stringify(this.contact_),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Não foi possível alterar o produto",
              message: e[0].message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
          this.shouldShow = false;
        });
    },
    fetchContactGroups() {
      fetch(`${this.$store.state.apiUrl}contact-groups`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.contact_groups = json));
    },
  },
  name: "DriverModal",
};
</script>
<style scoped>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-input {
  width: 100% !important;
  margin-top: 5px !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
</style>